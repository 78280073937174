@import "normalize.css";
@import "@blueprintjs/core/lib/css/blueprint.css";
/* // include blueprint-icons.css for icon font support */
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";



.generic-table {
  width: 100% !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  --border-opacity: 1 !important;
  border-color: #cbd5e0 !important;
  border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.generic-table.dark-header table th {
        --bg-opacity: 1 !important;
        background-color: #4a5568 !important;
        background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
        --text-opacity: 1 !important;
        color: #fff !important;
        color: rgba(255, 255, 255, var(--text-opacity)) !important;
      }

.generic-table.border-top {
    border-top-width: 1px !important;
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

.generic-table.border-top table {
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }

.generic-table.border-top table th:first-child {
        border-top-left-radius: 0.2rem;
      }

.generic-table.border-top table th:last-child {
        border-top-right-radius: 0.2rem;
      }

.generic-table table {
    width: 100%;
  }

.generic-table table.border {
      border-style: none;
    }

.generic-table table th,
    .generic-table table td {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
      vertical-align: middle !important;
    }

.generic-table table thead {
      border-bottom-width: 1px;
    }

.generic-table table thead th {
        text-align: left !important;
      }

.generic-table table tbody td {
        word-wrap: break-word;
        overflow-wrap: break-word;
      }

.generic-table table tbody .loading:hover td, .generic-table table tbody .no-data:hover td {
          cursor: default !important;
        }


body {
	margin: 0;
	padding: 0;
}

.footer-logos {
	display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12rem;
    margin-right: 0.25rem;
    margin-bottom: 3rem;
    mix-blend-mode: multiply;
    grid-column-gap: 12%;
    -webkit-column-gap: 12%;
    column-gap: 12%;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.footer-logos>img:first-child {
	width: auto;
	height: 100px;
}

.footer-logos>img:nth-child(2),
.footer-logos>img:nth-child(3) {
	height: 56px;
}

.shadow {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.font-bold {
	font-weight: 700 !important;
}

.slick-slide .flex {
	display: flex !important;

}

.slickSlider .slick-slider .bp5-icon-chevron-left,
.slickSlider .slick-slider .bp5-icon-chevron-right {
	top: 50%;
	transform: translate(0, -50%);
	bottom: inherit !important;
}

.slickSlider .slick-initialized .slick-slide {
	display: block;
	padding: 0 8px;
}

.slickSlider .slick-slider .bp5-card {
	width: 100% !important; 
	height: 62px !important;
}

.slickSlider .bp5-label {
	padding-left: 10px !important;
}

.slickSlider .slick-dots {
	bottom: -40px !important;
}

.slickSlider {
	display: flex !important;
}

.survey-school-name {
	color: #b85410 !important;
	font-size: 26px !important;
}

.text-blue-second {
	--text-opacity: 1;
	color: #619cd1;
	color: rgba(97, 156, 209, var(--text-opacity));
}

.welcome-page-title {
	font-size: 1.5rem;
	font-weight: 700;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

@media (max-width: 500px) {
	.welcome-page-title {
		font-size: 1.25rem;
		font-weight: 700;
	}

	.survey-locale-buttons {
		display: flex;
		flex-direction: column;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

.text-blue-600 {
	--text-opacity: 1;
	color: #1A2654 !important;
	color: rgba(26, 38, 84, var(--text-opacity));
}

.uppercase {
	text-transform: uppercase;
}

.mb-0 {
	margin-bottom: 0;
}

.lh-1 {
	line-height: 1;
}

.w-full {
	width: 100%;
}

.text-lg {
	font-size: 1.125rem;
}

.max-640 {
	max-width: 640px !important;
}

.pb-24 {
	padding-bottom: 6rem;
}

.pt-12 {
	padding-top: 3rem;
}

.p-4 {
	padding: 1rem;
}

.bp5-button.bp5-intent-primary {
	--bg-opacity: 1;
	background-color: #1a2654;
	background-color: rgba(26, 38, 84, var(--bg-opacity));
}

.pass-center {
	display: flex;
	margin-left: auto;
	margin-right: auto;
}

.start-btn {
	width: 33.333333% !important;
	background-color: #1a2654 !important;
	height: 68px !important;
	display: flex;
	margin-right: auto;
	margin-left: auto;
}

.bg-blue-600 {
	--bg-opacity: 1;
	background-color: #1A2654 !important;
	background-color: rgba(26, 38, 84, var(--bg-opacity)) !important;
}

.step:focus{
	outline: none !important;
}

.stepper {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	text-align: center !important;
	font-size: 1.25rem !important;
	font-weight: 700 !important;
}

.stepper .step {
	display: flex;
	justify-content: center;
	align-items: center;
	--text-opacity: 1;
	color: #fff;
	color: rgba(255, 255, 255, var(--text-opacity));
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 9999px;
	line-height: 1;
}

.stepper .step.active {
	--bg-opacity: 1;
	background-color: #1A2654;
	background-color: rgba(26, 38, 84, var(--bg-opacity));
	border-width: 4px;
	--border-opacity: 1;
	border-color: #a0aec0;
	border-color: rgba(160, 174, 192, var(--border-opacity));
}

.stepper .step.completed {
	--bg-opacity: 1;
	background-color: #3DCC91;
	background-color: rgba(61, 204, 145, var(--bg-opacity));
	border-width: 4px;
	--border-opacity: 1;
	border-color: #0F9960;
	border-color: rgba(15, 153, 96, var(--border-opacity));
}

.stepper .step.pending {
	--bg-opacity: 1;
	background-color: #fff;
	background-color: rgba(255, 255, 255, var(--bg-opacity));
	--text-opacity: 1;
	color: #000;
	color: rgba(0, 0, 0, var(--text-opacity));
	border-width: 4px;
	--border-opacity: 1;
	border-color: #a0aec0;
	border-color: rgba(160, 174, 192, var(--border-opacity));
}

.stepper .line {
	flex-grow: 1;
	display: inline-block;
}

.stepper .line.green {
	border-top-width: 4px;
	--border-opacity: 1;
	border-color: #0F9960;
	border-color: rgba(15, 153, 96, 1);
}

.stepper .line.grey {
	border-top-width: 4px;
	--border-opacity: 1;
	border-color: #a0aec0;
	border-color: rgba(160, 174, 192, 1);
	border-style: dashed;
}


.survey-question-card-wrapper {
	display: flex;
	flex-wrap: wrap;
}


.survey-question-card {
	display: flex;
	flex-direction: column;
	grid-gap: 1rem;
	gap: 1rem;
	padding: 1rem;
	border-width: 1px;
	--bg-opacity: 1;
	background-color: #f7fafc;
	background-color: rgba(247, 250, 252, var(--bg-opacity));
	--border-opacity: 1;
	border-color: #cbd5e0;
	border-color: rgba(203, 213, 224, var(--border-opacity));
	border-radius: 0.25rem;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	text-align: center;
}

.survey-question-card>div:first-child {
	text-align: left;
	font-size: 1.25rem;
	font-weight: 500;
	display: flex;
	align-items: center;
}

.survey-question-card>div:last-child {
	display: flex;
}

.survey-question-card>div:last-child>div {
	display: flex;
	flex: 1 1 0%;
	align-items: stretch;
}

.survey-question-card>div:last-child.not-applicable-answer>div:last-child>.bp5-button {
	margin-left: 1.25rem;
}

.survey-question-card>div:last-child.usual-answer>div>.bp5-intent-primary,
.survey-question-card>div:last-child.not-applicable-answer>div>.bp5-intent-primary {
	padding: 1px 1px !important;
}

@media (min-width: 768px) {

	.survey-question-card {
		flex-direction: row;

		flex-wrap: wrap;

		justify-content: space-between
	}

	.survey-question-card>div:first-child {
		flex: 1 0 calc(45% - 1rem);
	}

	.survey-question-card>div:last-child {
		flex: 1 0 55%;
	}

	.survey-question-card:hover {
		background: rgb(97, 156, 209, 0.5);
	}
}

.survey-arrows-button-size {
  width: 3.5rem;
  height: 3.5rem;
}

/* .bp5-intent-primary{
  background-color: rgba(26,38,84,.5) !important;
} */

.navbtn {
  background-color: #1a2654 !important;
}

.navbtn.bp5-disabled {
	opacity: .5 !important; 
}

.button-min-height {
  min-height: 4rem;
}


.text-xl {
  font-size: 1.25rem;
}
.sm\:text-xl {
  font-size: 1.25rem;
}
.md\:text-xl {
  font-size: 1.25rem;
}
.lg\:text-xl {
  font-size: 1.25rem;
}
.xl\:text-xl {
  font-size: 1.25rem;
}


.survey-question-card > div:last-child.usual-answer > div > .bp5-intent-primary, .survey-question-card > div:last-child.not-applicable-answer > div > .bp5-intent-primary {
  padding: 1px 1px !important;
}

@media (max-width: 605px) {
  .usual-answer {
    flex-direction: column;
  }
}

.question-container-max-width {
  max-width: 68.75rem;
}

@media (min-width: 1280px) {
  .question-container-max-width {
    max-width: unset;
    padding: 0 10%
  }
}


.welcome-page-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 2rem;
}


@media (max-width: 500px) {
  .welcome-page-title {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .survey-locale-buttons {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

/* .line .grey{
  background-color: none !important;
} */
.bp5-intent-primary {
	background: #1A2654 !important;
}

.bp5-button.bp5-intent-primary:hover {
	background-color: #273777 !important;
}

.bp5-portal.confirm-alert-portal .bp5-overlay {
	z-index: 100 !important;
  }

.bp5-portal.notes-dialog-portal .bp5-overlay, .bp5-portal.strategy-details-portal .bp5-overlay, .bp5-portal.answer-set-portal .bp5-overlay, .bp5-portal.activity-content-portal .bp5-overlay, .bp5-portal.update-implementation-status-portal .bp5-overlay {
	z-index: 21 !important;
  }

.bp5-portal.upsert-resource-portal .bp5-overlay {
	z-index: 22 !important;
  }

.bp5-portal.district-setup-container .bp5-overlay {
	overflow: auto !important;
  }

.bp5-portal .locale-selector-portal .bp5-transition-container, .bp5-portal .date-input-portal .bp5-transition-container {
	z-index: 22 !important;
  }


.bp5-alert-body{
	flex-direction: column !important;
    justify-content: center !important;
    text-align: center !important;
}

.bp5-dialog.wrap-title .bp5-heading {
	white-space: normal !important;
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
  }

.bp5-dialog .bp5-dialog-header .bp5-heading {
	--text-opacity: 1;
	color: #106BA3;
	color: rgba(16, 107, 163, var(--text-opacity));
  }

.bp5-dialog .bp5-dialog-header .bp5-icon {
	margin: 0 auto 16px !important;
	--text-opacity: 1;
	color: #106BA3;
	color: rgba(16, 107, 163, var(--text-opacity));
  }

.bp5-dialog .bp5-dialog-body {
  margin-bottom: 0 !important;
  line-height: inherit !important;
}

span.bp5-icon-info-sign{
	display: flex;
    align-items: center;
    justify-content: center;
    
/* 	
	svg {
      background-image: url(../src/static/images/infoIcon.svg);
      display: block;
      background-size: cover;
      path{
        display: none;
      }
    } */
	}
	
	.lang-menu-scroll {
    max-height: 70vh;
    overflow: auto;
}
.bp5-menu {
	background: #ffffff;
    border-radius: 2px;
    color: #1c2127;
    list-style: none;
    margin: 0;
    min-width: 0;
    padding: 5px;
    text-align: left;
}

.bp5-popover .bp5-popover-content {
	border-radius: 3px;
	position: relative;
}

.bp5-menu-item.bp5-active {
	background-color: #137cbd;
	color: #fff;
}
.slickSlider .slick-slider .bp5-icon-chevron-left, .slickSlider .slick-slider .bp5-icon-chevron-right {
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	bottom: inherit!important;
}
.bp5-elevation-0 {
	box-shadow: 0 0 0 1px rgba(16,22,26,.15), 0 0 0 rgba(16,22,26,0), 0 0 0 rgba(16,22,26,0); 
}
.bp5-card { 
	border-radius: 3px;
	box-shadow: 0 0 0 1px rgba(16,22,26,.15), 0 0 0 rgba(16,22,26,0), 0 0 0 rgba(16,22,26,0);
	padding: 20px;
	transition: box-shadow .2s cubic-bezier(.4,1,.75,.9),-webkit-transform .2s cubic-bezier(.4,1,.75,.9);
	transition: transform .2s cubic-bezier(.4,1,.75,.9),box-shadow .2s cubic-bezier(.4,1,.75,.9);
	transition: transform .2s cubic-bezier(.4,1,.75,.9),box-shadow .2s cubic-bezier(.4,1,.75,.9),-webkit-transform .2s cubic-bezier(.4,1,.75,.9);
}
.bp5-card p {
	margin-bottom: 0px;
	font-size: 1.25rem;
	line-height: 1;
}
.flex-grow {
	flex-grow: 1;
}
*, :after, :before {
	box-sizing: border-box;
	border: 0 solid #e2e8f0;
}
.border-gray-400 {
	--border-opacity: 1;
	border-color: #cbd5e0;
	border-color: rgba(203,213,224,var(--border-opacity));
}
.bp5-input-group.bp5-large .bp5-input {
	font-size: 16px;
	height: 40px;
	line-height: 40px;
}
.bp5-input-group .bp5-input {
	position: relative;
	width: 100%;
}
.bp5-input {
	-webkit-appearance: none;
	appearance: none;
	background: #fff;
	border: none;
	border-radius: 3px;
	box-shadow: 0 0 0 0 rgba(19,124,189,0), 0 0 0 0 rgba(19,124,189,0), inset 0 0 0 1px rgba(16,22,26,.15), inset 0 1px 1px rgba(16,22,26,.2);
	color: #182026;
	font-size: 14px;
	font-weight: 400;
	height: 30px;
	line-height: 30px;
	outline: none;
	padding: 0 10px;
	transition: box-shadow .1s cubic-bezier(.4,1,.75,.9);
	vertical-align: middle;
}
.max-640 {
	max-width: 700px !important;
}
.bp5-form-group label.bp5-label {
	margin-bottom: 5px;
	line-height: 22px;
}
.bp5-form-group { 
	margin: 1rem 0rem;
}
.bp5-focus-disabled :focus, .bp5-focus-disabled :focus~.bp5-control-indicator, .bp5-button:focus {
	outline: none!important;
}
.welcome-page-title { 
	line-height: 1;
}
.question-container-max-width .text-2xl {
	line-height: 22px;
}
.sm\:text-2xl {
	font-size: 1.5rem;
	line-height: 1.5rem;
}
.bp5-alert .bp5-button:not([class*=bp5-intent-]) {
	background-color: #f5f8fa;
	background-image: linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0));
	box-shadow: inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1);
	color: #182026;
}
 
.bp5-alert .bp5-alert-footer .bp5-button {
	margin: 5px;
}
.bp5-alert .bp5-button.bp5-intent-warning {
	background-color: #d9822b;
	background-image: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
	box-shadow: inset 0 0 0 1px rgba(16,22,26,.4), inset 0 -1px 0 rgba(16,22,26,.2);
	color: #fff;
}
.bp5-alert .bp5-alert-footer  .bp5-button {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	font-size: 14px;
	justify-content: center;
	padding: 5px 10px;
	text-align: left;
	vertical-align: middle;
	min-height: 30px;
	min-width: 30px;
}
.bp5-alert .bp5-alert-footer .bp5-button-text {
	flex: 0 0 auto;
}
.bp5-alert-body .bp5-icon { 
	margin: 0 auto 16px;
}
.bp5-alert-contents p {
	margin-bottom: 0;
}
.bp5-dialog {
	background: #ebf1f5;
} 
.bp5-dialog .bp5-button.bp5-intent-warning:not(.bp5-disabled):not(.bp5-minimal):not(.bp5-outlined):hover {
    background: #ec9a3c;
    color: #fff;
}
.bp5-dialog .bp5-icon.bp5-intent-warning {
	color: #155b98;
}
.footer-logos {
	margin-top: 5rem;
}
/* for scrollbar */ 
/* width */
::-webkit-scrollbar { 
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.recipe-toaster
{
  position: fixed !important;
  bottom: 0;
  z-index: 999;
  overflow: visible !important;
}


.mw-100 {
	max-width: 100% !important;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.slickSlider{
		justify-content: none !important;
	}
	.slickSliderDiv{
		max-width: 100% !important;
	}

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

	.slickSlider{
		justify-content: none !important;
	}
	.slickSliderDiv{
		max-width: 100% !important;
	}
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.slickSlider{
		justify-content: none !important;
	}
	.slickSliderDiv{
		max-width: 100% !important;
	}
	
}

.bordered-body {
    border-width: 14px;
    --border-opacity: 1;
    border-color: #f55656;
    border-color: rgba(245,86,86,var(--border-opacity));
}


.z-50 {
    z-index: 50 !important;
}


.bg-red-600 {
	--bg-opacity: 1;
	background-color: #F55656;
	background-color: rgba(245, 86, 86, var(--bg-opacity));
}
  

@media (max-width: 800px){
	.not-applicable-answer {
		flex-direction: column !important;
	}

	.not-applicable-answer>div:last-child>.bp5-button {
		margin-left: auto !important;
		margin-top: 1.25rem;
		margin-right: auto !important;
	}
}

